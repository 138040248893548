import React, { FunctionComponent, useContext } from "react"
import { useState } from "react"

interface Value {
    setError: (error: Error | undefined) => void
    globalError: Error | undefined
}

export const defaultValue: Value = {
    setError: () => {},
    globalError: undefined
}

const ErrorContext = React.createContext(defaultValue)

export const ErrorProvider: FunctionComponent = ({ children }) => {
    const [globalError, setError] = useState<Error | undefined>(defaultValue.globalError)

    return <ErrorContext.Provider value={{ globalError, setError }}>{children}</ErrorContext.Provider>
}

export const useErrorContext = () => {
    return useContext(ErrorContext)
}
