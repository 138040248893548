import { FunctionComponent, useContext, useEffect, useState } from "react"
import "../assets/app.css"
import { BrowserRouter } from "react-router-dom"
import { AuthContext } from "../providers/AuthProvider"
import FeedbackBar from "./FeedbackBar"
import Main from "./Main"
import Footer from "./Footer"
import { useServiceWorker } from "../useServiceWorker"
import ConnectivityChecker from "./ConnectivityChecker"
import ErrorBoundaryContainer from "components/errorBoundary/ErrorBoundaryContainer"

import * as Sentry from "@sentry/react"
import { useErrorContext } from "providers/ErrorProvider"

const SystemMessage: FunctionComponent = ({ children }) => <div className="systemMessage">{children}</div>

const App: FunctionComponent = () => {
    const { Message } = useContext(AuthContext)

    const { showReload, reloadPage, initialised } = useServiceWorker()
    const [isGlobalError, setIsGlobalError] = useState(false)
    const errorContext = useErrorContext()

    useEffect(() => {
        listenerToUnhandledRejection()
    }, [])

    useEffect(() => {
        if (errorContext.globalError) {
            setIsGlobalError(true)
        }
    }, [errorContext])

    const listenerToUnhandledRejection = () => {
        window.removeEventListener("unhandledrejection" ,() => {
            
        })  
        window.addEventListener("unhandledrejection", (event) => {
            if (errorContext) {
               const error = event?.reason?.stack || event?.reason || "An unknow error occured"
               errorContext.setError(error)
               throw error
            }
        })
    }

    return (
        <>
            {isGlobalError ? (
               <ErrorBoundaryContainer globalError={Sentry.getIsolationScope().getLastBreadcrumb()}/>
            ) : (
                <Sentry.ErrorBoundary
                    fallback={errorData => {
                        return <ErrorBoundaryContainer errorData={errorData} />
                    }}
                >
                    <div className="systemMessages">
                        {initialised && <SystemMessage>{Message("offlineUse")}</SystemMessage>}
                        {showReload && (
                            <SystemMessage>
                                {Message("newVersionAvailable")}{" "}
                                <button onClick={reloadPage}>{Message("updateNow")}</button>
                            </SystemMessage>
                        )}
                    </div>
                    <FeedbackBar />
                    <ConnectivityChecker />
                    <BrowserRouter>
                        <Main />
                    </BrowserRouter>
                    <Footer />
                </Sentry.ErrorBoundary>
            )}
        </>
    )
}

export default App
