import { FunctionComponent, useContext } from "react"
import "./ErrorBoundaryContainer.scss"
import Footer from "components/Footer"
import { AuthContext } from "providers/AuthProvider"
import { Breadcrumb } from "@sentry/react"

interface Props {
    errorData?: {
        error: unknown
        componentStack: string
        eventId: string
        resetError(): void
    }
    globalError?: Breadcrumb
}

const ErrorBoundaryContainer: FunctionComponent<Props> = ({ errorData, globalError }: Props) => {
    const { whiteLabelConfig, Message } = useContext(AuthContext)
    const { error, eventId, resetError } = errorData || {
        error: globalError?.message,
        componentStack: "",
        eventId: globalError?.event_id || "",
        resetError: () => {
            window.location.reload()
        }
    }

    let finalErrorMessage
    if (error instanceof Error) {
        finalErrorMessage = error.message
    } else {
        finalErrorMessage = JSON.stringify(error)
    }

    const reloadPage = () => {
        window.location.replace("/")
        localStorage.clear()
        resetError()
    }

    return (
        <>
            <nav className="topBar">
                <img src={whiteLabelConfig.context + "/logo.png"} alt={Message("orgName")} />
            </nav>
            <div className="feedbackBar errors">
                <div className="errorBoundaryContainer">
                    <h3>{Message("errorBoundaryContainerTitle")}</h3>
                    <p className="info">{Message("errorBoundaryContainerInitialInfo")}</p>
                    <div className="errorData">
                        <p>
                            {Message("errorBoundaryContainerEventId")}: <code>{eventId}</code>
                        </p>
                        <p>
                            {Message("errorBoundaryContainerErrorMessage")}: <code>{finalErrorMessage}</code>
                        </p>
                    </div>
                    <p className="info">{Message("errorBoundaryContainerShareInfo")}</p>
                    <div className="reloadButton">
                        <button onClick={reloadPage}>{Message("errorBoundaryContainerReload")}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ErrorBoundaryContainer
